import React, { useEffect } from "react";
import { PageProps, navigate } from "gatsby";

interface HomePageLocationState {
  firstLogIn?: boolean;
}

export default function HomePage({
  location: { state },
}: PageProps<unknown, unknown, HomePageLocationState>) {
  useEffect(() => {
    const firstLogIn = state?.firstLogIn;
    navigate("/apps", { state: { firstLogIn } });
  }, []);

  return <></>;
}
